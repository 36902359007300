import React from 'react';
import { Link } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export const ProductOverview = props => {
  const { sprayers } = props;
  const { variants } = sprayers;

  return (
    <div className="container product-overview-container">
      <Grid container>
        <Grid item className="product-image" xs={12} md={6}>
          <img
            src={sprayers.featured_image}
            alt="photograph of selected sprayer"
          />
        </Grid>
        <Grid item className="product-overview-inner" xs={12} md={6}>
          <div className="product-path">
            <Link to="/sprayers" className="kos__anchor">
              Sprayers
            </Link>
            <ChevronRightIcon style={{ fontSize: 12 }} />
            <span>{sprayers.type}</span>
          </div>
          <div className="product-overview-details">
            <h1 className="product-overview-title">{sprayers.title}</h1>
            <div className="product-overview-codes">
              <div className="product-overview-section">
                <div className="overview-code-section">
                  <p className="overview-code-title">SKU</p>
                  <p className="overview-code-number">{variants[0].sku}</p>
                </div>
              </div>
              <div className="overview-code-divider" />
              <div>
                <div className="overview-code-section">
                  <p className="overview-code-title">WEIGHT</p>
                  <p className="overview-code-number">
                    {variants[0].weight_with_unit}
                  </p>
                </div>
              </div>
            </div>
            <div className="product-overview-description">
              <h6>Overview:</h6>
              <p>{sprayers.description}</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

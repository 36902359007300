import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const ProductCard = props => {
  const { sprayer } = props;

  return (
    <div className="kos__sprayerCard kos__sprayerCard--related">
      <div className="kos__sprayerCard__imageWrapper">
        <img
          className="kos__sprayerCard__img"
          src={sprayer.featured_image}
          alt={sprayer.title}
        />
      </div>
      <div className="kos__sprayerCard__sku">
        {`SKU: ${sprayer.variants[0] && sprayer.variants[0].sku}`}
      </div>
      <div className="kos__sprayerCard__content">{sprayer.title}</div>
      <div className="kos__sprayerCard__footer">
        <Link
          className="button is-rounded kos__btn--green kos__btn--big"
          to={`/products/${sprayer.handle}`}
        >
          <span>Details</span>
          <ChevronRightIcon />
        </Link>
      </div>
    </div>
  );
};

const RelatedProducts = props => {
  const { sprayers } = props;
  const firstRelated = sprayers.related.slice(0, 4);

  return (
    <div className="container kos__relatedProducts">
      <div className="kos__relatedProducts__header">
        <p className="kos__relatedProducts__subtitle">RELATED</p>
        <p className="kos__relatedProduct__title">PRODUCTS</p>
      </div>

      <Grid container>
        {firstRelated.map((sprayer, i) => {
          return <ProductCard key={i} sprayer={sprayer} />;
        })}
      </Grid>
    </div>
  );
};

export default RelatedProducts;
